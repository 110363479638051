import React from "react";
import { Link } from "react-router-dom";
import BaseLink from "../../base/BaseLink/BaseLink";
import { TextRegular } from "../../base/BaseText";
import { FAQ_LINK } from "../../helpers/constants";

const Footer = () => {
  const openPrivacyPolicy = () => {
    window.open(
      "https://www.starhub.com/about-us/legal-notices-and-terms/personal-data-protection.html"
    );
  };

  const handleFAQClick = () => {
    // window.open(FAQ_LINK, "_blank");
    window.open("https://www.starhub.com/personal/support/article.html?id=7WR3NAdbPD7Um0YCZJox9A", "_blank")
  };

  const handleTnCclick = () => {
    window.open("https://www.starhub.com/content/dam/starhub/legal-notices-and-terms/consumer/screen-repair.pdf");
  }

  const handleWTUCclick = () => {
    window.open(process.env.REACT_APP_MY_ASURION_SUR_REDIRECT_URL, "_blank")
  }

  return (
    <footer className="bg-srgray-secondary text-2xl font-Lato-Regular pt-8">
      <div className="w-4/5 p-2 ml-auto mr-auto text-white py-8 pt-10">
        <div className="pb-8 text-app-nova text-3xl">
          <TextRegular>Privacy Policy</TextRegular>
        </div>
        <p className="pb-8 text-srgray-septenary">
          By using the services found on this website, you consent to StarHub
          collecting, using and/or disclosing your personal data in accordance
          with the StarHub privacy policy{" "}
          <BaseLink
            onClick={openPrivacyPolicy}
            className="text-srgray-septenary underline"
          >
            here
          </BaseLink>
          .
          <br />
          <br />
        </p>
        <hr className="border-app-border"></hr>
        <div className="mt-8 text-app-nova text-3xl">
          <TextRegular>Legal Notice</TextRegular>
        </div>
        <ul className="mt-6 leading-4r">
          <li>
            {/*<Link to="/terms-and-conditions" target="_blank">
              Terms and Conditions
            </Link>*/}
            <BaseLink
              onClick={handleTnCclick}
              className="text-srgray-septenary"
            >
              Terms and Conditions
            </BaseLink>
          </li>
          <li>
            {/* <Link to="/terms-of-use" target="_blank">
              Website Terms of Use
            </Link> */}
            <BaseLink
              onClick={handleWTUCclick}
              className="text-srgray-septenary"
            >
              Website Terms of Use
            </BaseLink>
          </li>
          <li>
            {/*<Link to="/faq" target="_blank">
              FAQ
            </Link>*/}
            <BaseLink
              onClick={handleFAQClick}
              className="text-srgray-septenary"
            >
              FAQ
            </BaseLink>
          </li>
        </ul>
        <div className="pt-8">
          <div className="text-srgray-senary font-Lato-Light mb-12 text-xl">
            <p>
              © StarHub 2024. All rights reserved. StarHub SmartSupport is the
              property of StarHub. All rights reserved. Apple® and iPhone® are
              registered trademarks of Apple Inc. All other trademarks, service
              marks, and product brands that appear on this website are not
              owned by Asurion and are the property of their respective owners.
              StarHub is not affiliated with, sponsored by, or endorsed by Apple
              or any of the respective owners of the other trademarks, service
              marks and/or product brands cited.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
