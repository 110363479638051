import React, { useState } from "react";
import PropTypes from "prop-types";

import { 
  TextBold, 
  TextLight, 
  TextRegular 
} from "../../base/BaseText";
import BaseButton from "../../base/BaseButton/BaseButton";
import SchedulePickupIcon from "../../images/home/icon-schedule-pickup.svg";
import ReturnRepairedPhoneIcon from "../../images/home/icon-return-repaired-phone.svg";
import CalendarIcon from "../../images/icon-calendar-selected.svg";
import BeforeRequestIcon from "../../images/before-request.svg";
import BackgroundPattern from "../../images/home/background-pattern.png";
import BackgroundTint from "../../images/home/grey-background-tint.png";
// import BannerImage from "../../images/home/new-updated-home-banner.png";
import BannerImage from "../../images/home/new-updated-home-banner2.png";
import HowItWorksImage from "../../images/home/screen-battery replacement.svg";

import BannerImageNew from "../../images/home/new-updated-home-banner3.jpg";
import BannerImageNewMobile from "../../images/home/new-updated-home-banner3-mobile.png";
import {
  HOMEPAGE_BUTTONS,
  FAQ_LINK,
  START_PREPARATION_LINK,
} from "../../helpers/constants";
import BasePopup from "../../base/BasePopup/BasePopup";
import PickUpAndReturnSchedule from "./HomepageStaticContent/PickUpAndReturnSchedule";
import DeviceEligibilityCriteria from "./HomepageStaticContent/DeviceEligibilityCriteria";
import TurnOffFindMyDevice from "./HomepageStaticContent/TurnOffFindMyDevice";
import InWarrantySupport from "./HomepageStaticContent/InWarrantySupport";

import moment from 'moment';
import surIcon from "../../images/home/cracked-phone.png";
import { useTranslation, Trans } from "react-i18next";

const Banner = ({ onGetStarted: handleGetStarted }) => (
  <article className="h-full relative bg-banner">
    <div>
      <div
        className="w-1/2x w-full sm:hidden lg:flex lg:absolute top-0 bottom-0 right-0 h-full z-10 lg:py-8"
        style={{
          background: `url(${BannerImageNew})`,
          backgroundSize: "cover",
          backgroundPositionX: "100%",
        }}
      >
        {/* <div className="overflow-hidden hidden lg:block mr-auto"
          style={{ borderRadius: "24px" }}>
          <img
            className="p-0 h-full"
            src={BannerImage}
            alt="Banner"
          />
        </div> */}
      </div>

      <div
        className="w-full sm:hidden lg:block lg:absolute top-0 bottom-0 right-0 h-full"
        style={{
          background: `url(${BackgroundTint})`,
          backgroundSize: "cover",
          backgroundPositionX: "100%",
        }}
      ></div>
      
      <div
        className="flex lg:hidden bg-center"
        // style={{
        //   background: `url(${BackgroundPattern})`,
        //   backgroundSize: "cover",
        //   backgroundPositionX: "100%",
        // }}
      >
        <div className="overflow-hidden mx-6x sm:mx-autox my-6x w-full"
          // style={{ borderRadius: "24px" }}
          >
          <img
            className="p-0 w-full sm:w-auto sm:mx-auto"
            src={BannerImageNewMobile}
            alt="Banner"
          />
        </div>
      </div>

      <div className="w-full lg:w-1/2 lg:p-8 relative text-center lg:text-left text-srgray-secondary z-10 lg:ml-auto">
        <div className="w-4/5 ml-auto mr-auto lg:ml-autox lg:mr-0 lg:ml-0">
          <h1 className="text-2.8 lg:text-3.8 px-8 lg:px-0 pt-8 lg:pt-16">
            <TextBold>Let us fix your device!</TextBold>
          </h1>
          <div className="h-2 bg-app-primary w-24 my-8 block mx-auto lg:mx-0"></div>
          <div className="text-1.6 mt-6 lg:my-8 px-8 lg:px-0 ml-auto">
            {/* <p>
              <TextRegular>
                If your phone has got a cracked screen or battery issue, let us
                fix it for you.
              </TextRegular>
            </p> */}
            <p>
              <TextRegular>The device you love, made good as new.</TextRegular>
            </p>
          </div>
          <div className="pb-8 lg:pb-16">
            <BaseButton
              text="Get Started"
              className="mx-auto lg:mx-0 border-white border lg:border-none mt-8 lg:mt-0"
              focused
              onClick={handleGetStarted}
            />
          </div>
          <div className="pb-8 lg:pb-16 text-1.3 text-srgray-primary">
            {/* <TextRegular>
              Terms and Conditions apply. Please check your eligibility to
              access repair
              <br /> and battery replacement service.
            </TextRegular> */}
            <TextRegular>
            Repair services are offered as separate services to StarHub SmartSupport customers. 
            Your access to the different types of repair services depend on the program that you are subscribed to. 
            You may refer to the terms and conditions of your program.
            </TextRegular>
          </div>
        </div>
      </div>
    </div>
  </article>
);

const Services = ({ onServiceClick: handleServiceBtnClick }) => {
  const handleFAQClick = () => {
    window.open(FAQ_LINK, "_blank");
  };

  const handleStartPreparationClick = () => {
    window.open(START_PREPARATION_LINK, "_blank");
  };

  return (
    // <></>
    <article className="bg-white w-full">
      <div className="text-center w-full">
        <div className="my-44px text-text-pureMidnight text-2.8 lg:text-3.8">
          <TextRegular>
            Repair Services
          </TextRegular>
        </div>
        <div className="flex justify-center flex-row flex-wrap text-srgray-secondary space-y-8 lg:space-y-0 mb-44px gap-4">
          <div className="flex flex-row flex-wrap justify-center space-y-8x md:space-y-0x gap-4">
            <div className="flex flex-row">
              <div>
                <img
                  className=""
                  width="70px"
                  height="70px"
                  src={BeforeRequestIcon}
                  alt="BeforeRequestIcon"
                />
              </div>
              <div className="w-24r text-left">
                <div className="text-2 font-medium">
                  <TextRegular>Before you request for service</TextRegular>
                </div>
                <div className="h-11r text-1.6 mt-4">
                  <TextLight>
                  Find out more about the different type of Repair services. Eligibility criteria applies.
                  </TextLight>
                </div>
                <div className="mt-4">
                  <button
                    className="w-20r px-2 py-3 text-default border border-app-primary text-app-secondary rounded"
                    onClick={() =>
                      handleServiceBtnClick(
                        HOMEPAGE_BUTTONS.ELIGIBILITY_CHECK_ARTICLE
                      )
                    }
                  >
                    {"Device Eligibility check"}
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-row sm:mt-16x lg:mt-0">
              <div>
                <img
                  className=""
                  width="70px"
                  height="70px"
                  src={CalendarIcon}
                  alt="CalendarIcon"
                />
              </div>
              <div className="w-24r text-left">
                <div className="text-2 font-medium">
                  <TextRegular>
                    Schedule for service at your convenience
                  </TextRegular>
                </div>
                <div className="h-11r text-1.6 mt-4">
                  <TextLight>
                  {"You can select for doorstep pick-up and return or walk in to authorised centres (by appointment only)."}
                  </TextLight>
                </div>
                {/* <div className="mt-4">
                  <button
                    className="w-20r px-2 py-3 text-default border border-app-primary text-app-secondary rounded"
                    onClick={() =>
                      handleServiceBtnClick(
                        HOMEPAGE_BUTTONS.PICK_UP_AND_RETURN_SCHEDULE
                      )
                    }
                  >
                    {HOMEPAGE_BUTTONS.PICK_UP_AND_RETURN_SCHEDULE}
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="flex flex-row flex-wrap justify-center space-y-8x md:space-y-0x gap-4">
            <div className="flex flex-row">
              <div>
                <img
                  className=""
                  width="70px"
                  height="70px"
                  src={SchedulePickupIcon}
                  alt="SchedulePickupIcon"
                />
              </div>
              <div className="w-24r text-left">
                <div className="text-2 font-medium">
                  <TextRegular>Prepare your device for pick-up</TextRegular>
                </div>
                <div className="h-11r text-1.6 mt-4">
                  <TextLight>
                  Backup your device, turn off activation lock, perform data wipe and factory reset.
                  </TextLight>
                </div>
                {/* <div className="mt-4">
                  <button
                    className="w-20r px-2 py-3 text-default border border-app-primary text-app-secondary rounded"
                    onClick={handleStartPreparationClick}
                  >
                    {HOMEPAGE_BUTTONS.START_PREPARATION}
                  </button>
                </div> */}
              </div>
            </div>
            <div className="flex flex-row">
              <div>
                <img
                  className=""
                  width="70px"
                  height="70px"
                  src={ReturnRepairedPhoneIcon}
                  alt="ReturnRepairedPhoneIcon"
                />
              </div>
              <div className="w-24r text-left">
                <div className="text-2 font-medium">
                  <TextRegular>
                    Service completion
                  </TextRegular>
                </div>
                <div className="h-11r text-1.6 mt-4">
                  <TextLight>
                  Requested service will be performed if device is  assessed to be suitable for Repair.
                  </TextLight>
                </div>
                {/* <div className="mt-4">
                  <button
                    className="w-20r px-2 py-3 text-default border border-app-primary text-app-secondary rounded"
                    onClick={handleFAQClick}
                  >
                    {HOMEPAGE_BUTTONS.FAQ}
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

const HowItWorks = ({ onServiceClick }) => (
  <></>
  // <article className="bg-srgray-septenary mt-7r">
  //   <div className="flex flex-col-reverse lg:flex-row mx-auto w-full lg:w-4/5">
  //     <div className="w-full lg:w-1/2 h-full">
  //       <img
  //         className="mx-auto md:ml-auto "
  //         src={HowItWorksImage}
  //         alt="howItWorksImage"
  //         width="450px"
  //         height="489px"
  //       />
  //     </div>
  //     <div className="w-full lg:w-1/2 pl-16 py-16 pr-8">
  //       <div className="w-full">
  //         <div className="text-6xl text-text-pureMidnight">
  //           <TextRegular>How it works</TextRegular>
  //         </div>
  //         <div className="h-2 bg-app-primary w-24 my-8 mx-0"></div>
  //         <div className="text-1.6 text-srgray-base">
  //           <TextBold>Door-to-Door Pick-up and Return Service</TextBold>
  //         </div>
  //         <div className="mt-4 text-srgray-base text-1.6">
  //           <TextRegular>
  //             Stay in the comfort of your home while we pick-up your phone for
  //             Screen Repair or/and Battery Replacement and return it to you once
  //             it is completed. Fees apply.
  //           </TextRegular>
  //         </div>
  //         <div className="mt-4 text-srgray-base text-1.6">
  //           <div>
  //             <TextBold>
  //               Simply submit your request online in 3 simple steps:
  //             </TextBold>
  //           </div>
  //           <div className="mt-4 text-srgray-base text-1.6">
  //             <div>
  //               <TextRegular>
  //                 1. Check your phone for Screen Repair and/or Battery
  //                 Replacement suitability.
  //               </TextRegular>
  //             </div>
  //             <div>
  //               <TextRegular>
  //                 2. Schedule for pick-up and return of your phone based on your
  //                 availability.
  //               </TextRegular>
  //             </div>
  //             <div>
  //               <TextRegular>3. Prepare your phone for pick-up.</TextRegular>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="mt-4 text-srgray-base text-1.6">
  //           <TextRegular>
  //             Your phone will be returned to you after 2 business days from the
  //             day your phone is picked up
  //           </TextRegular>
  //         </div>
  //         <div className="mt-6 text-srgray-base text-1.6">
  //           <TextRegular>
  //             Enjoy a peace of mind with in- warranty support for Screen Repair
  //             and Battery Replacement. To find out more about our warranty
  //             coverage,{" "}
  //             <span
  //               className="text-app-secondary underline cursor-pointer"
  //               onClick={() => onServiceClick("IN_WARRANTY_SUPPORT")}
  //             >
  //               Click here
  //             </span>
  //             .
  //           </TextRegular>
  //         </div>
  //         <div className="mt-4 text-xl text-srgray-base">
  //           <TextRegular>
  //             Note: Screen Repair and Battery Replacement are offered as
  //             separate services to SmartSupport. Access is limited to StarHub
  //             SmartSupport customers and subject to eligibility criteria as set
  //             out in the terms and conditions.
  //           </TextRegular>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </article>
);

const ServiceModal = ({ isOpen, open, selectedService }) => {
  let content = null;

  if (selectedService === HOMEPAGE_BUTTONS.ELIGIBILITY_CHECK_ARTICLE) {
    content = <DeviceEligibilityCriteria title="Device Eligibility Criteria" />;
  }

  if (selectedService === HOMEPAGE_BUTTONS.PICK_UP_AND_RETURN_SCHEDULE) {
    content = (
      <PickUpAndReturnSchedule title="Pick-up and Return Time Schedule" />
    );
  }

  if (selectedService === HOMEPAGE_BUTTONS.START_PREPARATION) {
    content = <TurnOffFindMyDevice title="Turn off Find My Device" />;
  }

  if (selectedService === "IN_WARRANTY_SUPPORT") {
    content = <InWarrantySupport title="In-Warranty Support" />;
  }

  return (
    <BasePopup
      show={isOpen}
      global
      onClose={() => open(false)}
      title={null}
      className="w-11/12 md:w-2/3 lg:1/3"
    >
      {content}
    </BasePopup>
  );
};

const HomeBody = ({ onGetStarted }) => {
  const { t } = useTranslation();

  const [selectedButton, setSelectedButton] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleServiceBtnClick = (selectedService) => {
    setIsOpen(true);
    setSelectedButton(selectedService);
  };
  
  const surCampaignStart = process.env.REACT_APP_SUR_CAMPAIGN_START;
  const surCampaignEnd = process.env.REACT_APP_SUR_CAMPAIGN_END;
  const isSurCampaignEndDateActive = moment().isSameOrBefore(moment(surCampaignEnd), "day")
  const isSURCampaign = process.env.REACT_APP_SUR_CAMPAIGN_ENABLED === 'true' && isSurCampaignEndDateActive;

  // console.log("isSURCampaign", isSURCampaign)

  const [showSGCampaignPopup, setShowSGCampaignPopup] = useState(isSURCampaign);

  const startDateLabel = moment(surCampaignStart).format("D MMM")
  const endDateLabel = moment(surCampaignEnd).format("D MMM")
  const yearLabel = moment(surCampaignEnd).format("YYYY")

  const [showSURModal, setShowSURModal] = useState(isSURCampaign);
  const [showDualModal, setShowDualModal] = useState(isSURCampaign);
  const [showMyAsurionBetaModal, setShowMyAsurionBetaModal] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const toggleDualModal = () => {
    if (showMyAsurionBetaModal) {
      if (!isSURCampaign) {
        setShowDualModal(false);
      } else {
        setShowMyAsurionBetaModal(false);
        setShowSURModal(true);
      }
    } else {
      setShowDualModal(false);
    }
  };

  const handleClick = async (e) => {
    setDisabled(true);
    window.location = process.env.REACT_APP_MY_ASURION_SUR_REDIRECT_URL
  };

  return (
    <div className="flex flex-col">
      <Banner onGetStarted={onGetStarted} />
      <Services
        onServiceClick={(selectedService) =>
          handleServiceBtnClick(selectedService)
        }
      />
      <HowItWorks
        onServiceClick={(selectedService) =>
          handleServiceBtnClick(selectedService)
        }
      />
      <ServiceModal
        open={setIsOpen}
        selectedService={selectedButton}
        isOpen={isOpen}
      />

      {showDualModal &&
        <div className={`block z-50`}>
          <BasePopup
            show={true}
            onClose={toggleDualModal}
            className="campaign-modal overflow-auto sm:rounded-3xl w-full h-full sm:w-640px sm:h-auto"
            global
          >
            {showMyAsurionBetaModal &&
              <>
                <div className="mt-0 mx-auto text-center relative h-90% sm:h-auto flex flex-row">

                  <div className="my-auto">
                    <div className="mb-4 py-10px mx-auto px-14 sm:px-0">
                      <p className="inline-block mx-auto w:full sm:w-4/5 text-4xl font-bold mt-0 text-center ml-auto inline-block leading-tight">
                        <Trans i18nKey={`BetaTest.Title`}></Trans>
                      </p>
                    </div>

                    <p className="text-center text-2xl px-7 sm:px-0">
                      <Trans i18nKey={`BetaTest.Subtitle`}></Trans>
                    </p>

                    <div className="w-full justify-center flex my-8">
                      <BaseButton
                        text={t('BetaTest.YesButton')}
                        className="mx-auto lg:mx-0 border-white border lg:border-none w-auto"
                        focused
                        onClick={handleClick}
                        disabled={disabled}
                      />
                    </div>
                  </div>


                </div>
              </>
            }

            {showSURModal &&
              <div className="mt-0 mx-auto text-center relative h-90% sm:h-auto">

                <div className="flex sm:hidden absolutex flex-col items-center justify-center text-14px leading-tight text-white rounded rounded-full"
                  style={{ top: "0", left: "0", width: "110px", height: "110px", background: "#1ed760" }}>
                  <Trans i18nKey={`Campaign.BadgeLabel`}>
                    <span></span>
                  </Trans>
                </div>

                <div className="hidden sm:flex absolute flex-col items-center justify-center text-14px leading-tight text-white rounded rounded-full"
                  style={{ top: "-20px", width: "110px", height: "110px", background: "#1ed760" }}>
                  <Trans i18nKey={`Campaign.BadgeLabel`}>
                    <span></span>
                  </Trans>
                </div>

                <div className="absolutex pt-6 sm:pt-0 sm:static transform top-1/2 -Trans-y-1/2x sm:Trans-y-0">
                  <div className="flex justify-center items-center mb-6 pt-10px sm:pt-60px w-90% sm:w-1/2 mx-auto">

                    <p className="mt-0 text-5xl font-bold text-center ml-auto inline-block leading-tight">
                      <span style={{ paddingRight: "15px" }} className="block w-auto sm:w-270px" dangerouslySetInnerHTML={{ __html: t("Campaign.Title") }}></span>
                    </p>

                    <div className="mr-auto">
                      <div style={{ width: "75px" }}>
                        <img className="mr-auto" src={surIcon} style={{ width: "75px" }} />
                      </div>
                    </div>

                  </div>

                  <p className="text-center text-14px px-5 sm:px-0 mx-auto">
                    <Trans i18nKey={`Campaign.SubtitleLine1`}>
                    </Trans>
                  </p>

                  <p className="text-center text-14px px-5 sm:px-0 mx-auto">
                    <Trans i18nKey={`Campaign.SubtitleLine2`}>
                    </Trans>
                  </p>

                  <p className="text-center text-2xl px-5 sm:px-0 my-6 mx-auto font-bold" style={{ color: "#1ed760" }}>
                    <Trans i18nKey={`Campaign.DiscountNote`}>
                    </Trans>
                  </p>

                  <p className="text-center text-12px px-2 sm:px-5 pb-4" style={{ color: "#A5AAAF" }}>
                    {t("Campaign.Note", { startDate: startDateLabel, endDate: endDateLabel, year: yearLabel })}
                  </p>
                </div>

              </div>
            }

          </BasePopup>
        </div>
      }
    </div>
  );
};

HomeBody.propTypes = {
  onGetStarted: PropTypes.func.isRequired,
};

export default HomeBody;
